const baseimgurl = "https://www.lxrcsc.net:8086/";
const baseurl = "https://www.lxrcsc.net:8086";
// const baseimgurl = "https://localhost:44368/";
// const baseurl = "https://localhost:44368";
// const baseimgurl = "http://192.168.50.116:8112/";
// const baseurl = "http://192.168.50.116:8112";
const mapak = "npSffTzvQKCk81MMoovi6ZMP8UEaGrEG";
export default {
    baseurl,
    baseimgurl,
    mapak
}