import { render, staticRenderFns } from "./resumedetail.vue?vue&type=template&id=693b786f&scoped=true"
import script from "./resumedetail.vue?vue&type=script&lang=js"
export * from "./resumedetail.vue?vue&type=script&lang=js"
import style0 from "./resumedetail.vue?vue&type=style&index=0&id=693b786f&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "693b786f",
  null
  
)

export default component.exports